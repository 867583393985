<template>
  <div id="main">
    <div class="container">
      <div class="accordion">
        <Collapse
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  components: {
    Collapse: () => import('@/components/atoms/Collapse'),
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    categoryName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    itemsInclude() {
      if (!this.data.Itinerary.include) return '';
      return `<h4>${this.$t('product-page.includes')}</h4>${this.data.Itinerary.include.split('\n').map((item) => `<div class="d-flex p-0"><p><img src="${this.speedSizeDomain}/assets/img/check-blue.png" class="ml-2 check" alt="check-blue" width="15" height="15"/></p> <p>${item}</p></div>`).join('')}`;
    },
    itemsNotInclude() {
      if (!this.data.Itinerary.notInclude) return '';
      return `<h4 class="mt-3">${this.$t('product-page.notIncludes')}</h4>${this.data.Itinerary.notInclude.split('\n').map((item) => {
        if (item.indexOf('•\t') > -1) {
          const strItem = item.replace('•\t', '');
          return `<div class="d-flex p-0"><p><img src="${this.speedSizeDomain}/assets/img/close_red.png" class="ml-2 close" alt="close-red" width="15" height="15"/></p> <p>${strItem}</p></div>`;
        } else {
          return `<div class="d-flex p-0"><p>${item}</p></div>`;
        }
      }).join('')}`;
    },
  },
  watch: {
    lang: 'updateTitle',
  },
  data() {
    return {
      items: [],
    };
  },
  beforeMount() {
    this.updateTitle();
  },
  methods: {
    updateTitle() {
      if (this.categoryName === 'Hotel_Only') {
        this.items = [
          {
            title: this.$t('product-page.about-hotel-info'),
            message: this.data.description.content,
            fac: this.data.facilitiesData,
            type: 'about',
          },
          {
            title: this.$t('product-page.map'),
            type: 'map',
            address: `${this.data.city.content} ${this.data.address.content}`,
          },
          {
            title: this.$t('product-page.facilities'),
            fac: this.data.facilitiesData,
            type: 'facilities',
          },
          // {
          //   title: this.$t('product-page.general-terms'),
          //   message:
          //     this.data.notesTranslation[this.lang]
          //     || this.$t('product-page.mobile-general-description'),
          // },
          // {
          //   title: this.$t('product-page.package-remark'),
          //   message: this.data.remarkTranslation[this.lang] || this.data?.remark,
          // },
        ];
      } else if (this.categoryName === 'Organize_tour_packages') {
        this.items = [
          {
            title: this.$t('product-page.map'),
            type: 'map',
            address: `${this.data.hotels[0].cityName} ${this.data.hotels[0].hotelName}`,
          },
          // {
          //   title: this.$t('product-page.package-remark'),
          //   message:
          //   this.categoryName === 'vacation_pack'
          //     ? this.data.hotels[0].desc
          //     : this.data.Itinerary?.include || '',
          //   fac:
          //   this.categoryName === 'vacation_pack'
          //     ? this.data.hotels[0].fac
          //     : '',
          //   translations: {
          //     fac: this.data.translations?.fac,
          //   },
          //   type: 'fac',
          // },
          {
            title: this.$t('product-page.package-remark'),
            type: 'html',
            message: this.itemsInclude + this.itemsNotInclude,
          },
          {
            title: this.$t('product-page.general-terms'),
            type: 'html',
            message: this.replaceURLToLink(this.data.notesTranslation[this.lang] || this.$t('product-page.product-detail-information')),
          },
        ];
      } else if (this.categoryName === 'sport_pack') {
        this.items = [
          {
            title: this.$t('product-page.about-hotel-info'),
            message: this.data?.hotels[0]?.desc,
            fac: this.data?.hotels[0]?.fac,
            translations: {
              fac: this.data?.translations?.fac,
            },
            type: 'fac',
          },
          {
            title: this.$t('product-page.map'),
            type: 'map',
            address: `${this.data?.hotels[0]?.cityName} ${this.data?.hotels[0].hotelName}`,
          },
          {
            title: this.$t('product-page.package-remark'),
            message: this.data?.productContent?.text || this.data?.remarkTranslation?.[this.lang] || this.data?.remark,
            type: 'html',
          },
          {
            title: this.$t('product-page.general-terms'),
            type: 'html',
            message: this.data?.productContent?.notes || this.replaceURLToLink(this.data.notesTranslation?.[this.lang] || this.$t('product-page.product-detail-information')),
          },
        ];
      } else {
        this.items = [
          {
            title: this.$t('product-page.about-hotel-info'),
            message:
            this.categoryName === 'vacation_pack'
              ? this.data?.hotels[0].desc
              : this.data?.Itinerary.include || '',
            fac:
            this.categoryName === 'vacation_pack'
              ? this.data?.hotels[0].fac
              : '',
            translations: {
              fac: this.data?.translations?.fac,
            },
            type: 'fac',
          },
          {
            title: this.$t('product-page.map'),
            type: 'map',
            address: `${this.data?.hotels[0].cityName} ${this.data?.hotels[0].hotelName}`,
          },
          {
            title: this.$t('product-page.package-remark'),
            message: this.data?.productContent?.text || this.data?.remarkTranslation[this.lang] || this.data.remark,
            type: 'html',
          },
          {
            title: this.$t('product-page.general-terms'),
            type: 'html',
            message: this.data?.productContent?.notes || this.replaceURLToLink(this.data.notesTranslation[this.lang] || this.$t('product-page.product-detail-information')),
          },
        ];
      }
    },
    replaceURLToLink(pContent) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return pContent.replace(urlRegex, (url) => `<a href="${url}">${url}</a>`);
    },
  },
};
</script>
<style scoped>
.bonauf-mobile #main {
  display: block;
}
</style>
